import React, { useState } from "react";
import ServiceList from "./service/ServiceList";
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

const OrderServiceScreen = ({onChange, setService}) => {

    const setData = (title,screen) => {
        setService(title)
        onChange(screen)
    }

    const Pulse = styled.div`animation: 1s ${keyframes`${fadeIn}`}`;

    const nextScreen = 'SexScreen'

    // Ручной массаж
    const services_array = [
        {
            id: 0,
            name:'Общий массаж всего тела',
            time:'60 минут',
            cost: '2000₽'
        },
        {
            id: 1,
            name:'Общий массаж всего тела',
            time:'90 минут',
            cost: '2500₽'
        },
        {
            id: 2,
            name:'Массаж спины',
            time:'40 минут',
            cost: '1500₽'
        },
        {
            id: 3,
            name:'Массаж ШВЗ и головы',
            time:'30 минут',
            cost: '800₽'
        },
        {
            id: 4,
            name:'Массаж ног (стоп, голени, бёдра, ягодицы)',
            time:'40 минут',
            cost: '2000₽'
        },
        {
            id: 5,
            name:'Массаж стоп',
            time:'30 минут',
            cost: '1000₽'
        },
        {
            id: 6,
            name:'Массаж живота',
            time:'30 минут',
            cost: '800₽'
        },
        {
            id: 7,
            name:'Висцеральный массаж живота',
            time:'60 минут',
            cost: '2000₽'
        },
        {
            id: 8,
            name:'Лечебный массаж (банки, акупунктура, тейпирование)',
            time:'80 минут',
            cost: '2200₽'
        },
        {
            id: 9,
            name:'Спортивный массаж',
            time:'60 минут',
            cost: '2200₽'
        },
        {
            id: 10,
            name:'Лимфодренажный массаж',
            time:'60 минут',
            cost: '2000₽'
        },
    ]

    // Relax программы
    const services_array2 = [
        {
            id: 0,
            name:'Расслабляющий массаж',
            time:'60 минут',
            cost: '2000₽'
        },
        {
            id: 1,
            name:'Расслабляющий массаж',
            time:'90 минут',
            cost: '2500₽'
        },
        {
            id: 2,
            name:'Парный массаж',
            time:'60 минут',
            cost: '3800₽'
        },
        {
            id: 3,
            name:'Массаж в 4 руки',
            time:'60 минут',
            cost: '3000₽'
        },
        {
            id: 4,
            name:'Арома массаж',
            time:'60 минут',
            cost: '2200₽'
        },
    ]

    // Массаж лица
    const services_array3 = [
        {
            id: 0,
            name:'Классический массаж лица',
            time:'40 минут',
            cost: '1200₽'
        },
        {
            id: 1,
            name:'Скульптурный массаж лица (акупунктура, тейпирование)',
            time:'60 минут',
            cost: '1500₽'
        },
        {
            id: 2,
            name:'Буккальный массаж лица',
            time:'60 минут',
            cost: '1500₽'
        },
    ]

    // Детский массаж
    const services_array4 = [
        {
            id: 0,
            name:'Общий массаж до 1 годика',
            time:'30 / 40 минут',
            cost: '1000₽'
        },
        {
            id: 1,
            name:'От 1-го  до 6-ти лет',
            time:'45 минут',
            cost: '1200₽'
        },
        {
            id: 2,
            name:'От 6-ти до 14 лет',
            time:'60 минут',
            cost: '1500₽'
        },
    ]

    // Коррекция фигуры
    const services_array5 = [
        {
            id: 1,
            name:'Ноги, ягодицы',
            time:'40 минут',
            cost: '1800₽'
        },
        {
            id: 2,
            name:'Живот, бока',
            time:'30 минут',
            cost: '1000₽'
        },
        {
            id: 3,
            name:'Ноги, ягодицы, живот, бока',
            time:'60 минут',
            cost: '2200₽'
        },
        {
            id: 4,
            name:'Всё тело',
            time:'80 минут',
            cost: '2500₽'
        },
    ]

    // Аппаратный массаж
    const services_array6 = [
        {
            id: 0,
            name:'Выбрационный аппарат Turbo J 5',
            time:'30 минут',
            cost: '800₽'
        },
        {
            id: 1,
            name:'Выбрационный аппарат Turbo J 5',
            time:'60 минут',
            cost: '1200₽'
        },
        {
            id: 2,
            name:'Ваккумный массаж',
            time:'30 минут',
            cost: '800₽'
        },
        {
            id: 3,
            name:'Ваккумный массаж',
            time:'60 минут',
            cost: '1200₽'
        },
        {
            id: 4,
            name:'Кавитация',
            time:'1 зона',
            cost: '500₽'
        },
    ]

    // NEW массажи
    const services_array7 = [
        {
            id: 0,
            name:'Огненный массаж',
            time:'60 минут',
            cost: '2500₽'
        },
        {
            id: 1,
            name:'Йога - массаж',
            time:'60 минут',
            cost: '2500₽'
        },
        {
            id: 2,
            name:'Массаж для беременных',
            time:'60 минут',
            cost:'2200₽'
        },
        {
            id: 3,
            name:'Выездной массаж',
            time:'60 минут',
            cost:'3000₽'
        }
    ]

    const services_array8 = [
        {
            id: 0,
            name:'Общий массаж всего тела + массаж лица',
            time:'',
            cost: '2500₽'
        },
    ]

    const [services, setServices] = useState(services_array)

    const position_names = ['Ручной массаж', 'Relax программы', 'Массаж лица', 'Детский массаж', 'Коррекция фигуры', 'Аппаратный массаж', 'NEW массажи', 'Акции'];

    const handleNameChange = (titleTemp) =>{
        if (titleTemp === 'Ручной массаж'){
            setServices(services_array)
        }
        else if (titleTemp === position_names[1]){
            setServices(services_array2)
        }
        else if (titleTemp === position_names[2]){
            setServices(services_array3)
        }
        else if (titleTemp === position_names[3]){
            setServices(services_array4)
        }
        else if (titleTemp === position_names[4]){
            setServices(services_array5)
        }
        else if (titleTemp === position_names[5]){
            setServices(services_array6)
        }
        else if (titleTemp === position_names[6]){
            setServices(services_array7)
        }
        else if (titleTemp === position_names[7]){
            setServices(services_array8)
        }
        else if (titleTemp === position_names[8]){
            setServices(services_array8)
        }
    }

    const TestRender = ({serviceArray}) => {
        return (serviceArray.map((service) => 
        <div className="order-service-container" key={service.id} onClick={() => setData(service.name, nextScreen)}>
            <div className="order-service-container-text-block">
                <h1>{service.name}</h1>
                <h3>{service.time}</h3>
            </div>
            <h3 className="service-price">{service.cost}</h3>
        </div>
        ))
    }

    return (
        <>
            <Pulse style={{width: '100%'}}>
                <div className="service-container">
                    <h1 className="service-title">Выберите массаж</h1>
                    <ServiceList onChange={handleNameChange}></ServiceList>
                    <div className="service-section">
                        <TestRender serviceArray={services}/>
                    </div>
                </div>
            </Pulse>

        </>
    )
}

export default OrderServiceScreen