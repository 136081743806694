import React from 'react'
import styled, { keyframes } from 'styled-components';
import { merge, fadeIn, pulse } from 'react-animations';


const PriceBox = ({ title }) => {
    
    const priceing = ['Ручной массаж', 'Relax программы', 'Массаж лица', 'Детский массаж', 'Коррекция фигуры', 'Аппаратный массаж', 'NEW массажи', 'Акции'];
    var check = title
    
    const fadeZoomIn = merge(fadeIn,pulse)

    const Pulse = styled.div`animation: 1s ${keyframes`${fadeZoomIn}`}`;

    // Ручной массаж
    const priceList = [
        {
            id: 0,
            name:'Общий массаж всего тела',
            time:'60 минут',
            cost: '2000₽'
        },
        {
            id: 1,
            name:'Общий массаж всего тела',
            time:'90 минут',
            cost: '2500₽'
        },
        {
            id: 2,
            name:'Массаж спины',
            time:'40 минут',
            cost: '1500₽'
        },
        {
            id: 3,
            name:'Массаж ШВЗ и головы',
            time:'30 минут',
            cost: '800₽'
        },
        {
            id: 4,
            name:'Массаж ног (стоп, голени, бёдра, ягодицы)',
            time:'40 минут',
            cost: '2000₽'
        },
        {
            id: 5,
            name:'Массаж стоп',
            time:'30 минут',
            cost: '1000₽'
        },
        {
            id: 6,
            name:'Массаж живота',
            time:'30 минут',
            cost: '800₽'
        },
        {
            id: 7,
            name:'Висцеральный массаж живота',
            time:'60 минут',
            cost: '2000₽'
        },
        {
            id: 8,
            name:'Лечебный массаж (банки, акупунктура, тейпирование)',
            time:'80 минут',
            cost: '2200₽'
        },
        {
            id: 9,
            name:'Спортивный массаж',
            time:'60 минут',
            cost: '2200₽'
        },
        {
            id: 10,
            name:'Лимфодренажный массаж',
            time:'60 минут',
            cost: '2000₽'
        },
    ]

    // Relax программы
    const priceList2 = [
        {
            id: 0,
            name:'Расслабляющий массаж',
            time:'60 минут',
            cost: '2000₽'
        },
        {
            id: 1,
            name:'Расслабляющий массаж',
            time:'90 минут',
            cost: '2500₽'
        },
        {
            id: 2,
            name:'Парный массаж',
            time:'60 минут',
            cost: '3800₽'
        },
        {
            id: 3,
            name:'Массаж в 4 руки',
            time:'60 минут',
            cost: '3000₽'
        },
        {
            id: 4,
            name:'Арома массаж',
            time:'60 минут',
            cost: '2200₽'
        },
    ]

    // Массаж лица
    const priceList3 = [
        {
            id: 0,
            name:'Классический массаж лица',
            time:'40 минут',
            cost: '1200₽'
        },
        {
            id: 1,
            name:'Скульптурный массаж лица (акупунктура, тейпирование)',
            time:'60 минут',
            cost: '1500₽'
        },
        {
            id: 2,
            name:'Буккальный массаж лица',
            time:'60 минут',
            cost: '1500₽'
        },
    ]

    // Детский массаж
    const priceList4 = [
        {
            id: 0,
            name:'Общий массаж до 1 годика',
            time:'30 / 40 минут',
            cost: '1000₽'
        },
        {
            id: 1,
            name:'От 1-го  до 6-ти лет',
            time:'45 минут',
            cost: '1200₽'
        },
        {
            id: 2,
            name:'От 6-ти до 14 лет',
            time:'60 минут',
            cost: '1500₽'
        },
    ]

    // Коррекция фигуры
    const priceList5 = [
        {
            id: 1,
            name:'Ноги, ягодицы',
            time:'40 минут',
            cost: '1800₽'
        },
        {
            id: 2,
            name:'Живот, бока',
            time:'30 минут',
            cost: '1000₽'
        },
        {
            id: 3,
            name:'Ноги, ягодицы, живот, бока',
            time:'60 минут',
            cost: '2200₽'
        },
        {
            id: 4,
            name:'Всё тело',
            time:'80 минут',
            cost: '2500₽'
        },
    ]

    // Аппаратный массаж
    const priceList6 = [
        {
            id: 0,
            name:'Выбрационный аппарат Turbo J 5',
            time:'30 минут',
            cost: '800₽'
        },
        {
            id: 1,
            name:'Выбрационный аппарат Turbo J 5',
            time:'60 минут',
            cost: '1200₽'
        },
        {
            id: 2,
            name:'Ваккумный массаж',
            time:'30 минут',
            cost: '800₽'
        },
        {
            id: 3,
            name:'Ваккумный массаж',
            time:'60 минут',
            cost: '1200₽'
        },
        {
            id: 4,
            name:'Кавитация',
            time:'1 зона',
            cost: '500₽'
        },
    ]

    // NEW массажи
    const priceList7 = [
        {
            id: 0,
            name:'Огненный массаж',
            time:'60 минут',
            cost: '2500₽'
        },
        {
            id: 1,
            name:'Йога - массаж',
            time:'60 минут',
            cost: '2500₽'
        },
        {
            id: 2,
            name:'Массаж для беременных',
            time:'60 минут',
            cost:'2200₽'
        },
        {
            id: 3,
            name:'Выездной массаж',
            time:'60 минут',
            cost:'3000₽'
        }
    ]

    const priceList8 = [
        {
            id: 0,
            name:'Общий массаж всего тела + массаж лица',
            time:'',
            cost: '2500₽'
        },
    ]

    const setPositions = (priceList) => {
        return (priceList.map((section)=>
            <div className="price-box-section" key={section.id}>
                <div className="name-section">
                    <h3>{section.name}</h3>
                    <p>{section.time}</p>
                </div>
                <h3 className='price-box-price'>{section.cost}</h3>
            </div>
        ))
    }

    function setInfo(check){
        if (title === priceing[0]){
            return(
                <Pulse className='pulse-section'>  
                <div className='price-box'>                    
                    {setPositions(priceList)}
                </div>
                </Pulse>
            )
        }
        else if (title === priceing[1]){
            return(
                <Pulse className='pulse-section'>  
                <div className='price-box'> 
                    {setPositions(priceList2)}
                </div>
                </Pulse>  
            )
        }
        else if (title === priceing[2]){
            return(
                <Pulse className='pulse-section'>  
                <div className='price-box'>
                    {setPositions(priceList3)}
                </div>
                </Pulse>
            )
        }
        else if (title === priceing[3]){
            return(
                <Pulse className='pulse-section'>  
                <div className='price-box'>
                    {setPositions(priceList4)}
                </div>
                </Pulse>
                
            )
        }
        else if (title === priceing[4]){
            return(
                <Pulse className='pulse-section'>  
                <div className='price-box'>
                    {setPositions(priceList5)}
                </div>
                </Pulse>
            )
        }
        else if (title === priceing[5]){
            return(
                <Pulse className='pulse-section'>  
                <div className='price-box'>
                 {setPositions(priceList6)}
                </div>
                </Pulse>
            )
        }
        else if (title === priceing[6]){
            return(
                <Pulse className='pulse-section'>  
                <div className='price-box'>
                    {setPositions(priceList7)}
                </div>
                </Pulse>
            )
        }
        else if (title === priceing[7]){
            return(
                <Pulse className='pulse-section'>  
                <div className='price-box'>
                    {setPositions(priceList8)}
                </div>
                </Pulse>
            )
        }
        else {
            return(
                <Pulse className='pulse-section'>  
                <div className='price-box'>
                    {setPositions(priceList)}
                </div>
                </Pulse>
            )
        }
    }

    return setInfo(check)

}

export default PriceBox;